import * as React from 'react'
import { Link } from 'gatsby'

const isBrowser = typeof window !== 'undefined' && !window.STORYBOOK_ENV

export const replaceLink = (domNode) => {
    let siteUrl

    if (isBrowser) {
        siteUrl = window.location.href
    }

    if (domNode.name === 'a') {
        const currentLink = domNode.attribs.href
        const isInternalLink = currentLink.includes(siteUrl)

        if (isInternalLink) {
            const location = currentLink.replace(siteUrl, '')
            return <Link to={location}>{domNode.children[0].data}</Link>
        }
    }
}

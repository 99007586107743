import * as React from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'
import './styles.scss'

const Affiliate = ({ style }) => {
    return (
        <div className="c-affiliate">
            <div className="c-affiliate__inner">
                Powered by
                {style === 'light' ? (
                    <StaticImage
                        src={'../../../images/quotezone-red.png'}
                        height={30}
                        alt={'Quotezone.co.uk'}
                        className={'c-affiliate__image'}
                    />
                ) : (
                    <StaticImage
                        src={'../../../images/quotezone-white.png'}
                        height={30}
                        alt={'Quotezone.co.uk'}
                        className={'c-affiliate__image'}
                    />
                )}
            </div>
        </div>
    )
}

Affiliate.propTypes = {
    style: PropTypes.oneOf(['light', 'option1', 'option2'])
}

Affiliate.defaultProps = {
    style: 'option1'
}

export default Affiliate

import * as React from 'react'
import PropTypes from 'prop-types'
import { domToReact } from 'html-react-parser'
import loadable from '@loadable/component'
import { replaceLink } from '/src/functions/parse/gatsbyLink'

const CheckList = loadable(() => import('/src/components/Lists/CheckList'))

export const parseStyledCheckList = (variant) => {
    const listItem = {
        replace: (domNode) => {
            if (domNode.name === 'li') {
                return (
                    <li>
                        <span>{domToReact(domNode.children)}</span>
                    </li>
                )
            }
        }
    }

    const list = {
        replace: (domNode) => {
            if (domNode.name === 'ul') {
                return (
                    <CheckList variant={variant}>
                        {domToReact(domNode.children, listItem)}
                    </CheckList>
                )
            }

            replaceLink(domNode)
        }
    }

    return list
}

parseStyledCheckList.propTypes = {
    /**
     * Specify a different style variant
     */
    variant: PropTypes.oneOf(['light', 'dark'])
}

import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import calculateColumn from '/src/functions/columns/calculateColumn'
import parse from 'html-react-parser'
import { parseStyledCheckList } from '/src/functions/parse/styledCheckList'
import joinClasses from '/src/functions/joinClasses'
import Affiliate from '/src/components/Basic/Affiliate'
import './styles.scss'

const ButtonLink = loadable(() => import('/src/components/Basic/Buttons/ButtonLink'))
const CountdownTimer = loadable(() => import('/src/components/Basic/CountdownTimer'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const Image = loadable(() => import('/src/components/Media/Image'))
const ImageVideoSwap = loadable(() => import('/src/components/Base/ImageVideoSwap'))
const Section = loadable(() => import('/src/components/Structure/Section/Section'))
const Snowfall = loadable(() => import('react-snowfall'))

const Hero = ({
    isFrontPage,
    orientation,
    style,
    columnSizes,
    title,
    makeTextLarger,
    replaceTitle,
    titleReplacementImage,
    content,
    mediaLocation,
    mediaType,
    image,
    imageAlignment,
    video,
    addCountdownTimer,
    countdownTimer,
    addCtas,
    ctasRepeater,
    addExtended,
    extendedContent,
    addAffiliate,
    node,
    utm,
    addSnow
}) => {
    const containerClasses = [
        'c-hero',
        `c-hero--${orientation}`,
        `c-hero--${style}`,
        ...(orientation !== 'vertical' ? [`c-hero--${mediaLocation}`] : []),
        `c-hero--image-${imageAlignment}`
    ]

    const titleClasses = ['c-hero__title', ...(makeTextLarger ? ['c-hero__title--large'] : [])]

    const concatenatedContainerClasses = joinClasses(containerClasses)
    const concatenatedTitleClasses = joinClasses(titleClasses)

    // If style selected is gradient style pass along the prop to section
    const [variant, setVariant] = useState('light')

    useEffect(() => {
        if (style === 'option1') {
            setVariant('gradient')
        }
    }, [style])

    // Figure out how to size the columns
    const mediaColumnWidth = calculateColumn('media', columnSizes, mediaLocation)
    const contentColumnWidth = calculateColumn('content', columnSizes, mediaLocation)

    const generateTitle = (headingType) => {
        if (replaceTitle) {
            return <Image data={titleReplacementImage} />
        } else {
            if (headingType === 'h1') {
                return <h1 className={concatenatedTitleClasses}>{title}</h1>
            } else if (headingType === 'h2') {
                return <h2 className={concatenatedTitleClasses}>{title}</h2>
            }
        }
    }

    return (
        <Section variant={variant} size={'sm'} className={concatenatedContainerClasses}>
            {addSnow && <Snowfall />}

            <div className="row">
                <div className={`${orientation !== 'vertical' ? contentColumnWidth : 'col-12'} c-hero__content-column`}>
                    {title && (isFrontPage ? generateTitle('h2') : generateTitle('h1'))}

                    <div className="c-hero__content">
                        {content && style === 'light'
                            ? parse(content, parseStyledCheckList('light'))
                            : parse(content, parseStyledCheckList('dark'))}
                    </div>

                    {addCountdownTimer && <CountdownTimer date={new Date(countdownTimer)} />}

                    {addCtas && ctasRepeater && (
                        <CtaContainer variant={'column'}>
                            {ctasRepeater.map((node, index) => {
                                return (
                                    <ButtonLink
                                        key={index}
                                        data={node.callToAction}
                                        variant={node.buttonStyle}
                                        size={'lg'}
                                        icon={'arrow'}
                                        utm={utm}
                                        className={'c-hero__cta'}
                                    />
                                )
                            })}
                        </CtaContainer>
                    )}

                    {node && <div className="c-hero__node">{node}</div>}

                    {addExtended && extendedContent && <div className="c-hero__extended">{parse(extendedContent)}</div>}
                </div>
                <div className={`${orientation !== 'vertical' ? mediaColumnWidth : 'col-12'} c-hero__media-column`}>
                    <div className={`c-hero__media-container c-hero__media-container--align-${imageAlignment}`}>
                        <ImageVideoSwap video={video} image={image} mediaType={mediaType} className={'c-hero__image'} />

                        {addAffiliate && <Affiliate style={style} />}
                    </div>
                </div>
            </div>
        </Section>
    )
}

Hero.propTypes = {
    /**
     * Whether the current page is the homepage or not (changes logo to either have an H1 or a span wrapped around it)
     */
    isFrontPage: PropTypes.bool,
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    style: PropTypes.oneOf(['light', 'option1', 'option2']),
    columnSizes: PropTypes.oneOf([
        'media-6-content-6',
        'media-5-content-7',
        'media-4-content-8',
        'media-3-content-9',
        'offset-1-media-5-content-5',
        'offset-1-media-4-content-6',
        'offset-1-media-3-content-7',
        'offset-1-media-2-content-8'
    ]).isRequired,
    title: PropTypes.string.isRequired,
    makeTextLarger: PropTypes.bool,
    replaceTitle: PropTypes.bool,
    titleReplacementImage: PropTypes.object,
    content: PropTypes.string.isRequired,
    mediaLocation: PropTypes.oneOf(['left', 'right']).isRequired,
    mediaType: PropTypes.oneOf(['image', 'video']).isRequired,
    image: PropTypes.object,
    imageAlignment: PropTypes.oneOf(['default', 'top', 'bottom']),
    video: PropTypes.string,
    addCountdownTimer: PropTypes.bool,
    countdownTimer: PropTypes.string,
    addCtas: PropTypes.bool,
    ctasRepeater: PropTypes.arrayOf(
        PropTypes.shape({
            callToAction: PropTypes.shape({
                url: PropTypes.string,
                title: PropTypes.string,
                target: PropTypes.string
            }),
            buttonStyle: PropTypes.oneOf(['primary', 'inverse', 'other'])
        })
    ),
    addExtended: PropTypes.bool,
    extendedContent: PropTypes.string,
    addAffiliate: PropTypes.bool,
    /**
     * Optional additional node to display
     */
    node: PropTypes.node,
    /**
     * Optional page UTMs - e.g. `?utm_campaign=campaign_name&utm_source=source_name`
     */
    utm: PropTypes.string,
    addSnow: PropTypes.bool
}

Hero.defaultProps = {
    isFrontPage: false,
    orientation: 'horizontal',
    style: 'option1',
    columnSizes: 'media-5-content-7',
    title: 'Hero Title',
    makeTextLarger: false,
    replaceTitle: false,
    content: `<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    <ul>
        <li>Item 1</li>
        <li>Item 1</li>
        <li>Item 1</li>
    </ul>`,
    mediaLocation: 'left',
    mediaType: 'image',
    imageAlignment: 'default',
    addCountdownTimer: false,
    countdownTimer: 'March 16, 2023 00:00:00',
    addCtas: false,
    addExtended: false,
    extendedContent: '<p>I am some extended content <a href="#">with a link</a></p>',
    addAffilate: false,
    addSnow: false
}

export default Hero

export const query = graphql`
    fragment HeroForPageBuilder on WpPage_Acfpagebuilder_Layouts_Hero {
        fieldGroupName
        orientation
        style
        columnSizes
        title
        makeTextLarger
        replaceTitle
        titleReplacementImage {
            id
            localFile {
                childImageSharp {
                    gatsbyImageData(width: 700)
                }
            }
            altText
            title
        }
        content
        mediaLocation
        mediaType
        image {
            id
            localFile {
                childImageSharp {
                    gatsbyImageData(width: 520)
                }
            }
            altText
            title
        }
        imageAlignment
        video
        addCountdownTimer
        countdownTimer
        addCtas
        ctasRepeater {
            callToAction {
                target
                title
                url
            }
            buttonStyle
        }
        addExtended
        extendedContent
        addAffiliate
    }
`

const calculateColumn = (columnType, columnSizeValue, mediaLocation) => {
    let columnSize = 'col-lg-6'

    if (columnType === 'media') {
        if (columnSizeValue === 'media-5-content-7') {
            columnSize = 'col-lg-5'
        } else if (columnSizeValue === 'media-4-content-8') {
            columnSize = 'col-lg-4'
        } else if (columnSizeValue === 'media-3-content-9') {
            columnSize = 'col-lg-3'
        } else if (columnSizeValue === 'offset-1-media-2-content-8') {
            if (mediaLocation === 'left') {
                columnSize = 'col-lg-2 offset-lg-1'
            } else {
                columnSize = 'col-lg-2'
            }
        } else if (columnSizeValue === 'offset-1-media-3-content-7') {
            if (mediaLocation === 'left') {
                columnSize = 'col-lg-3 offset-lg-1'
            } else {
                columnSize = 'col-lg-3'
            }
        } else if (columnSizeValue === 'offset-1-media-4-content-6') {
            if (mediaLocation === 'left') {
                columnSize = 'col-lg-4 offset-lg-1'
            } else {
                columnSize = 'col-lg-4'
            }
        } else if (columnSizeValue === 'offset-1-media-5-content-5') {
            if (mediaLocation === 'left') {
                columnSize = 'col-lg-5 offset-lg-1'
            } else {
                columnSize = 'col-lg-5'
            }
        }
    } else if (columnType === 'content') {
        if (columnSizeValue === 'media-5-content-7') {
            columnSize = 'col-lg-7'
        } else if (columnSizeValue === 'media-4-content-8') {
            columnSize = 'col-lg-8'
        } else if (columnSizeValue === 'media-3-content-9') {
            columnSize = 'col-lg-9'
        } else if (columnSizeValue === 'offset-1-media-2-content-8') {
            if (mediaLocation === 'right') {
                columnSize = 'col-lg-8 offset-lg-1'
            } else {
                columnSize = 'col-lg-8'
            }
        } else if (columnSizeValue === 'offset-1-media-3-content-7') {
            if (mediaLocation === 'right') {
                columnSize = 'col-lg-7 offset-lg-1'
            } else {
                columnSize = 'col-lg-7'
            }
        } else if (columnSizeValue === 'offset-1-media-4-content-6') {
            if (mediaLocation === 'right') {
                columnSize = 'col-lg-6 offset-lg-1'
            } else {
                columnSize = 'col-lg-6'
            }
        } else if (columnSizeValue === 'offset-1-media-5-content-5') {
            if (mediaLocation === 'right') {
                columnSize = 'col-lg-5 offset-lg-1'
            } else {
                columnSize = 'col-lg-5'
            }
        }
    }

    return columnSize
}

export default calculateColumn
